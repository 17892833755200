.__comm_banner_se {
    min-height: 200px;
    max-height: 380px;
    overflow: hidden;
    padding: 30px 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        height: 100%;
        z-index: -1;
    }

    h1 {
        font-size: 46px;
        font-weight: 600;
        margin-bottom: 15px;
        color: #fff;
    }

    p {
        font-size: 21px;
        color: #fff;
        font-weight: 300;
    }

    .__input_sec {
        margin: 30px 0;
        width: 55%;
        position: relative;
        border: 10px solid #ffffff30;
        border-radius: 55px;

        @media screen and (max-width: 500px) {
            width: 90%;
        }

        input {
            padding: 13px 30px;
            background: #fff;
            color: #000;
            border-radius: 50px;
            width: 100%;
            font-size: 20px;
            outline: none;
            height: 55px;
            border: none;
            padding-right: 60px;
        }

        button {
            position: absolute;
            right: 0;
            padding: 15px;
            outline: none;
            border: none;
            height: 55px;
            width: 55px;
            border-radius: 55px;
            background: #0780bd;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
        }
    }

}