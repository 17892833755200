.__model_class {
  .MuiDialog-paper {
    border-radius: 40px;

    @media screen and (max-width: 500px) {
      border-radius: 15px;
    }
  }

  .__log_wrap {
    width: 57vw;
    height: 83vh;
    display: flex;

    @media screen and (max-width: 800px) {
      flex-direction: column;
      height: 70vh;
    }

    @media screen and (max-width: 550px) {
      width: 80vw;
      height: 75vh;
    }

    img {
      width: 40%;
      height: 100%;
      object-fit: cover;

      @media screen and (max-width: 800px) {
        display: none;
      }
    }

    .__login_form {
      width: 60%;
      height: 100%;
      padding: 2% 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (max-width: 800px) {
        width: 100%;
      }
    }
  }

  .__or_sec {
    border-bottom: 1px solid #ddd;
    margin: 30px 0;
    position: relative;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    label {
      background: #ddd;
      border-radius: 30px;
      border: 10px solid #fff;
      position: absolute;
      top: -20px;
      font-size: 14px;
      padding: 10px;
      height: 60px;
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .__social_log_sec {
    margin: 10px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;

    @media screen and (max-width: 500px) {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }

    button {
      height: 48px;
      border-radius: 30px;
      border: none;
      background: #DF4930;
      color: #fff;
      width: 100%;

      @media screen and (max-width: 500px) {
        height: 40px;
      }

      svg {
        margin-right: 10px;
      }
    }
  }

}

.arabic{
  .__social_log_sec button svg{
    margin-left: 10px;
    margin-right: 0 !important;
  }
}