.__button_art {
    position: absolute;
    z-index: 10;
    background-color: transparent;
    border: none;
    bottom: 5%;
    font-size: 33px;
    color: #ffffffc2;
    font-weight: bold;
    transition: 0.4s;
    text-shadow: 0px 0px 4px #000;

    &:hover {
        color: #ffffff;
    }
    @media screen and (max-width: 988px) {
        font-size: 19px;
    }

    @media screen and (max-width: 555px) {
        span {
            display: none;
        }
    }
}

.__gradient_art {
    height: 120px;
    background: linear-gradient(180deg, #fff0 0%, #000000ba 100%);
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: 1.5rem !important;
}

.generate-video-section{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: start;
}

.countdown-timer{
    font-size: 13px;
    color: #0780bd;
}