.__footer {
    background: #0780bd 0% 0% no-repeat padding-box;
    padding: 15px;
    width: 100%;
    color: #fff;
    padding-top: 30px;
    padding-bottom: 0px;

    .__logo {
        height: 60px;
        object-fit: contain;
        border-radius: 8px;
        border: 2px solid #fff;
    }

    a {
        display: flex;
        font-size: 14px;
        margin-bottom: 12px;
        cursor: pointer;
        color: #fff;
        text-decoration: none;
        font-weight: 300;
    }

    h4 {
        font-size: 20px;
    }

    p {
        max-width: 60%;
        font-size: 15px;
        font-weight: 300;
        margin-top: 10px;
        text-align: left;

        b {
            font-weight: bold;
        }
    }
}

.__onmb {
    @media screen and (max-width: 500px) {
        flex-direction: column;
        text-align: center;
    }

    a {
        @media screen and (max-width: 500px) {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }
}

.iconSet {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    gap: 10px;  
    @media screen and (max-width: 500px) {
        flex-direction: column;
    }

    a {
        color: #fff;
        font-size: 15px;
        margin: 0 5px;
    }

    ._pr {
        a {
            color: #fff;
            font-size: 16px;
            margin: 0 5px;
            display: flex;
            align-items: center;
            border: 1px solid;
            padding: 3px 20px;
            border-radius: 20px;
        }
    }
}