.____common_model_class_desc {
    height: 55vh;
    overflow: auto;
    white-space: pre-line;
    padding-right: 10px;
  
}

.__single_context {
    margin-bottom: 16px;

    h1 {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 10px;
    }

    p {
        margin: 0;
        font-size: 13px;
        line-height: 1.7;
        word-wrap: break-word;
        padding-left: 10px;
    }
}
