.__faq {
    display: flex;
    gap: 10px;
    color: #444;
    text-decoration: none;
    align-items: center;
    margin-top: 7px;
    font-size: 15px;
    justify-content: end;
    cursor: pointer;
    width: max-content;
}
