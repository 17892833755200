.__more_img_subm {
    border: 1px dashed #969EAE;
    border-radius: 24px;
    display: flex;
    align-items: center;
    color: #969EAE;
    justify-content: center;
    margin-right: 20px;
    width: 70px;
    height: 70px;
    cursor: pointer;
}
