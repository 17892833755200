.__download {
    width: 100%;
    border-radius: 26px;
    overflow: hidden;
    height: 430px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0;

    h1 {
        font-size: 46px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    p {
        font-size: 23px;
        color: #2f3a52;
        margin-bottom: 40px;
    }

    img {
        width: 220px;
        height: 70px;
        @media screen and (max-width: 500px) {
            width: 130px;
            height: 40px;
          }
    }

    a {
        margin: 0 15px;
    }

    ._backgrd {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -2;
        margin: 0;
    }

    .__backgradient {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: -1;
        background: linear-gradient(180deg, #ffffffd4 30%, #ffffff00 100%);
        top: 0;
        left: 0;

    }

}