.__link_class {
    display: flex;
    grid-gap: 10px;
    align-items: center;

    img {
        height: 25px;
    }

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        background: #000;
        margin-top: 1px;
        border-radius: 5px;
        color: #fff;
        font-size: 12px;
        padding: 0 6px;
        grid-gap: 5px;
    }
    a{
        text-decoration: none;
    }
}


.screenShots {
    margin-top: 20px;
}

.screenShots img {
    width: 140px;
    margin: 15px;
}

.arabic .__link_class {
    direction: rtl;
}