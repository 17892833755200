.__awards_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 30px;
    width: 100%;

    .__dummy {
        border-radius: 20px;
        box-shadow: 0px 0px 10px #0002;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 235;
        width: 100%;
        cursor: pointer;
        font-size: 20px;
        font-weight: bold;
        color: #0083bb;
    }
}

.__sec_hd {
    margin-bottom: 30px;
    font-weight: 500;
    line-height: 1.2;
    position: relative;

    &:after {
        height: 7px;
        width: 60px;
        background: #61b2d5bf;
        content: " ";
        position: absolute;
        left: 0px;
        bottom: -10px;
        border-radius: 30px;
        border-left: 14px solid #0083bb;
    }
}


.singleBook {
    box-shadow: 0 0 7px #00000033;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    transition: .5s;
    text-decoration: none;
    color: #000;

    img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        filter: drop-shadow(0 2px 6px #0007);
    }

    .bookBind {
        position: absolute;
        width: 7px;
        height: 100%;
        border-right: 2px solid #00000088;
        box-shadow: 0 0 4px #ffffff73;
        left: 0;
        top: 0;
    }

    .subJect {
        top: 10px;
        position: absolute;
        right: 0;
        padding: 2px 10px;
        background: #c5393a;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        color: #fff;
        max-width: 140px;
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 0%;
        word-break: break-all;
    }

    h1 {
        margin: 0;
        margin-top: 10px;
        font-size: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 0%;
    }

    h5 {
        font-size: 18px;
        margin-top: 5px;
        margin-bottom: 5px;
        color: #767676 !important;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 0%;
    }

    h6 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 0%;
    }
}

.singleBookInner {
    padding: 15px;
}

.___chat_container_wrapper {
    width: 100%;
    margin: auto auto;
    min-height: calc(100vh - 150px);
    position: relative;

    .__chat_display {
        overflow: auto;
        background: #fff;
        padding: 20px;
        border-radius: 20px;
        
    }

    .___use_resp_set {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        margin-bottom: 20px;

        .___response {
            h2 {
                margin: 0;
                font-size: 18px;
            }

            p {
                margin-top: 5px;
                font-size: 18px;
                font-weight: 300;
                margin-bottom: 0;
                line-height: 1.6;
            }

        }
    }

.___user {
    color: #87d068;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }
}
    .___send_form {
        width: 100%;
        display: flex;
        position: absolute;
        bottom: 20px;
        overflow: hidden;
        border-radius: 20px;

        .___text_area {
            overflow-y: auto;
            padding-bottom: 1.2rem;
            padding-top: 1.2rem;
            border-radius: 20px;
            padding-left: 1rem;
            padding-right: 3rem;
            resize: none;
            outline: none;
            width: auto;
            flex: 1 1;
            max-height: 140px;
            font-size: 1rem;
            border: 1px solid #eee2;
            background-color: #ffffffe8;

            &:focus {
                border-color: #eee4;
                transition: 0.4s;
            }
        }

        button {
            background: linear-gradient(45deg, #0284c5, #00c4dd);
            right: 8px;
            bottom: 8px;
            height: 43px;
            border: none;
            border-radius: 10px;
            color: #fff !important;
            cursor: pointer;
            display: flex;
            font: inherit;
            font-size: 17px;
            font-weight: 500;
            justify-content: center;
            outline: inherit;
            padding: 12px 18px;
            transition: 0.5s;
            white-space: nowrap;
            align-items: center;
        }

        button:disabled {
            background: #687880;
            cursor: not-allowed;
        }
    }
}



.__container_head {
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    margin-bottom: 25px;

    span {
        font-size: 26px;
        font-weight: 600;
        display: flex;
        gap: 10px;
        align-items: center;
    }

    p {
        margin: 0;
        font-size: 13px;
    }
}


.__initial_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 68vh;
    flex-direction: column;
    border-radius: 10px;

    img {
        width: 200px;
        object-fit: contain;
        filter: brightness(1.2) contrast(1.5);

    }

    span {
        font-size: 16px;
        font-weight: 400;
    }
}

.__container_body {
    overflow: auto;
    height: calc(100vh - 316px);
    padding-right: 15px;
}


.__common_model_class_2{
    width: 65vw;
    margin: 0 auto;
}

.__right_btn_actions{
    position: absolute;
    right: 8px;
    bottom: 8px;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}
.__micr {
    padding: 0 !important;
    background: none !important;
    border: 0;
    width: 35px;
    height: 35px;
    cursor: pointer;

    img {
        width: 35px;
    height: 35px;
        object-fit: contain;
    }
}
.__loading {
    display: flex;
    align-items: center;
    gap: 11px;
    margin-left: 55px;

    img {
        width: 27px;
        height: 27px;
    }
}


.__share {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 10px;
    padding: 6px 15px;
    font-weight: 600;
}