.__sub_p_Block {
    padding: 50px 30px;
    padding-bottom: 40px;
    background: #EDEDED;
    border-radius: 45px;
    position: relative;
    min-height: 320px;

    h1 {
        font-size: 30px;
        margin: 0;
        margin-bottom: 10px;
    }

    h2 {
        font-size: 40px;
        font-weight: bold;
    }

    .recommend {
        padding: 3px 7px;
        background-color: #0780bd;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 1;
        border-radius: 10px;
        color: #fff;
        font-weight: bold;
        font-size: 13px;
        letter-spacing: 1px;
        padding-top: 4px;

    }
}

.input-group {
    width: 60%;
    display: inline-block;
    position: relative;
  
  
    input {
      width: 100%;
      border-radius: 7px !important;
      border: 1px solid gray;
      padding: 1em;
      margin: 0.5em 0;
      display: block;
    }
  
    label {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 14px;
    }
  
    svg {
      position: absolute;
      right: 15px;
    bottom: 22%;
    font-size: 30px;
    }
  }
  
  .multi-input {
    margin-top: 20px;
    width: 60%;
    display: flex;
    gap: 10px;
  }
  
  small {
    color: red;
    font-size: 10px;
    display: block;
  }