.__class_apps_set {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 25px;
    width: 100%;
    margin: 20px 0;

    .__single_app {
        border-radius: 35px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border: 1px solid #E3E3E3;
        padding: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        img {
            width: 70px;
            border-radius: 20px;
            height: 70px;
        }

        a {
            img {
                width: 25px !important;
                margin: 0 15px;
                border-radius: 0px !important;
                height: 25px !important;
            }
        }

        h1 {
            font-size: 17px;
            margin: 10px 0;
        }
    }
}

.__inr_p {
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    h2, img{
        display: none;
    }
}

