// @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap');



@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: 'Readex Pro', sans-serif;
}

*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 6px
}


/* Track */

*::-webkit-scrollbar-track {
    background: #EEEAEA;
    opacity: .7;
    border-radius: 6px
}


/* Handle */

*::-webkit-scrollbar-thumb {
    background: #0004;
    height: 25px;
    border-radius: 6px;
}


/* Handle on hover */

*::-webkit-scrollbar-thumb:hover {
    background: #0780bd;
}

.content-wrapper {
    min-height: 55vh;
    padding-top: 80px;

    @media screen and (max-width: 555px) {
        padding-top: 60px;
    }
}


.__common_grid_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 50px;
    margin: 30px 5px 15px;
}

.__common_page_head {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 30px;
}

.__trip_sub_title {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 30px;
    color: #0780bd;
}

.__add_comment_wrap {
    margin: 10px 0;

    .__comment_adding {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        position: relative;

        img {
            width: 45px;
            height: 45px;
            object-fit: cover;
            border-radius: 60px;
            margin-right: 15px;
        }

        div {
            width: 100%;
            text-align: center;
        }
    }
}


.__detail_content_wrapper {
    margin: 30px 0;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 994px) {
        flex-direction: column;
    }

    .__main_content {
        flex: 8;
        padding-right: 20px;

        @media screen and (max-width: 994px) {
            flex: 1;
            padding-right: 0px;
        }

        h1 {
            font-size: 33px;
            font-weight: bold;
            margin: 0;
            margin-bottom: 6px;
        }

        h2 {
            font-size: 21px;
            margin: 0;
        }

        p {
            font-size: 13px;
            color: #0780bd;
        }

        .__icon_set {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #eee;
            color: #969EAE;
            font-size: 14px;
            margin: 20px 0;


            div {
                display: flex;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-right: 30px;
                text-align: center;
                cursor: pointer;

                @media screen and (max-width: 560px) {
                    span {
                        display: none;
                    }
                }

                svg {
                    font-size: 44px;

                    @media screen and (max-width: 560px) {
                        font-size: 30px;
                    }
                }
            }
        }

        .__para {
            color: rgb(0, 0, 0);
            font-size: 16px;
            margin-top: 15px;
            text-align: justify;
            white-space: pre-line;
        }
    }

    .__related_content {
        flex: 3;
        padding-left: 20px;
        border-left: 1px solid #959fad;

        @media screen and (max-width: 994px) {
            margin-top: 40px;
            flex: 1;
            padding-left: 0px;
            border-left: none;
        }

        h2 {
            font-size: 27px;
            margin: 0;
            border-bottom: 1px solid #ddd;
            padding-bottom: 15px;
            margin-bottom: 15px;
        }

        .__related_item {
            display: flex;
            margin-bottom: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #ddd;
            cursor: pointer;

            img {
                height: 70px;
                width: 100px;
                object-fit: cover;
                border-radius: 10px;
                min-width: 100px;
            }

            div {
                margin: 0 10px;
            }

            h1 {
                font-size: 18px !important;
                margin-bottom: 10px !important;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                flex: 1 1 0%;
            }

            p {
                font-size: 13px !important;
                margin-bottom: 0px !important;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                flex: 1 1 0%;
                color: #bbb;
            }
        }
    }
}

.__article-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-gap: 30px;
    margin: 20px 0;
    width: 100%;
}

.__border_dashed {
    border-bottom: 1px dashed #969EAE;
    width: calc(100% - 2rem);
    margin: 20px auto;
    margin-bottom: 30px;
}

.__cover_image {
    border: 1px dashed #969EAE;
    border-radius: 34px;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    color: #969EAE;
    justify-content: space-between;
    cursor: pointer;

    div {
        display: flex;
        align-items: center;

        svg {
            font-size: 40px;
        }
    }

    span {
        margin: 0 15px;
        color: #000;
        font-weight: bold;
    }
}

.__more_img {
    border: 1px dashed #969EAE;
    border-radius: 34px;
    display: flex;
    align-items: center;
    color: #969EAE;
    justify-content: center;
    margin-right: 20px;
    width: 100px;
    height: 60px;
    cursor: pointer;
}

.__more_img_image {
    border-radius: 14px;
    margin-right: 20px;
    width: 60px;
    height: 60px;
    position: relative;
    border: 1px solid #ccc;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 14px;
    }

    span {
        position: absolute;
        top: -10px;
        right: -10px;

        button {
            width: 20px;
            height: 20px;
            padding: 0;
            min-height: 16px;

            svg {
                font-size: 1rem;
            }
        }
    }
}

.__addtrip {
    width: 82%;
    margin: 0 auto;

    @media screen and (max-width: 500px) {
        width: 90%;
    }

    h5 {
        font-weight: bold;
        font-size: 17px;
        margin-bottom: 15px;
    }
}

.__common_model_class {
    .MuiDialogContent-root {
        padding: 20px 10%;
    }
}

.__common_model_class_tabs {
    .MuiDialogContent-root {
        padding: 20px 6%;
    }
}


.__pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 500px) {
        flex-direction: column;
        height: 120px;
        justify-content: space-evenly;
    }

    Button {
        padding: 12px 25px;
        border: 1px solid #969EAE;
        margin: 15px;
        border-radius: 30px;
        text-decoration: none;
        transition: 0.4s;
        height: 60px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;

        @media screen and (max-width: 900px) {
            font-size: 13px;
            padding: 2px;
            margin: 5px;
            height: 25px;
            width: 25px;
            min-width: 25px;
        }
    }

    ._hand_btn {
        color: #747474;
        text-transform: capitalize;
        font-size: 17px;
        font-weight: 400;
        font-family: 'Readex Pro' !important;
        width: auto;
        margin: 0;

        @media screen and (max-width: 900px) {
            padding: 12px;
            font-size: 13px;
        }
    }

    .css-1ysyrvn-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
        color: #ffffff;
        border: 1px solid #39c7f1;
        background-color: #39c7f1;
    }
}

.__coverimg {
    border-radius: 34px;
    overflow: hidden;
    height: 200px;
    margin-top: 20px;
    width: 100%;
    position: relative;

    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    span {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

// custom caleder button
.__custom_caleder_btn {
    padding: 0 !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: end !important;
    position: absolute !important;
    top: -11px !important;
    right: 0 !important;
    min-width: 68vw !important;
    height: 48px !important;
    border-radius: 40px !important;
}

.MuiPickersToolbar-penIconButton {
    display: none !important;
}

.MuiMenuItem-root {
    font-family: 'Readex Pro', sans-serif !important;

}

.__fab_set {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 20px;
}

.__videos-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
}

.__photos-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    grid-gap: 30px;

    img {
        height: 170px;
        object-fit: cover;
        width: 100%;
        box-shadow: 0px 0px 10px #0005;
        border: 2px solid #fff;
        border-radius: 10px;
        cursor: pointer;
    }
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    height: 0;
    border-radius: 10px;
    overflow: hidden;
    background: #000;
    box-shadow: 0px 0px 10px #0005;
    border: 2px solid #fff;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.__banner_set {
    min-height: 600px;
}

.imageLoader {
    background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
    animation: 1.5s shine linear infinite;
    background-size: 200% 100%;
}


.arabic {

    .__common_grid_list,
    .__main_content,
    .__related_content,
    .__article-list {
        direction: rtl;
    }

    .__icon_set a {
        margin-left: 30px;
        margin-right: 0 !important;
    }

}

.arabic {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    button {
        direction: rtl;
        font-family: 'Readex Pro', sans-serif;
    }

    .__custom_caleder_btn {
        left: 0 !important;
        right: auto !important;
    }

    .__cover_image {
        direction: rtl;
    }

    .__more_img {
        margin-left: 20px;
        margin-right: 0px !important;
    }

    .__more_img_arb {
        direction: rtl;
    }

    .__add_comment_wrap .__comment_adding img {
        margin-right: 0px !important;
        margin-left: 15px;
    }
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    font-family: 'Readex Pro', sans-serif !important;
}

.highlighted {
    font-weight: bold;
    background: yellow
}

.__marg_ {
    margin: 0 !important;
    margin-top: 1.5rem !important;
}

.__new_submit_btn {
    min-width: auto !important;
    padding: 5px !important;
    width: 48px;
    line-height: 0;
    padding-left: 0px !important;
    margin: 0 !important;
    position: absolute !important;
    bottom: 18px;
    right: 18px;
}

.arabic {
    
.__new_submit_btn {
    bottom: 18px;
    right: auto;
    left: 18px;
    span{
        margin: 0 15px !important;
    }
    
}
}

._cht_full {
    margin-top: 15px;
    margin-bottom: 35px;
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 20px;

    .__chat_heading {
        margin-top: 0px;
        margin-bottom: 15px;
        font-size: 20px;
    }

    .__input_gpt {
        position: relative;

        button {
            position: absolute;
            top: 0;
            right: 0;
            min-width: 120px;
            width: 80px;
        }
    }

    .__resp_gpt {
        font-size: 15px;
        margin-top: 15px;
        font-weight: 300;
        margin-bottom: 0px;
        line-height: 1.5;
        padding: 10px 30px;

        h3 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
            text-transform: capitalize;
        }

        p {
            font-size: 15px;
            font-weight: 300;
            line-height: 1.8;
            text-align: justify;
        }
    }

}

.arabic {
    ._cht_full {

        .__input_gpt {

            button {
                position: absolute;
                top: 0;
                right: auto;
                left: 0;
                min-width: 120px;
                width: 80px;
            }
        }

    }
}

.__premim_qlit {
    width: 40px !important;
    height: 40px !important;
    position: absolute;
    bottom: -15px;
    right: 10px;
    filter: drop-shadow(0 2px 6px #0009) !important;
}


.poetry2Line {
    width: 235px;
    text-align: justify;
    color: black;
    font-size: 13px;
    white-space: nowrap;
    min-width: 200px;
}


.__buttons {
    display: flex;
    gap: 10px;

    button {
        margin: 0;
        padding: 2px 6px;
        border: 1px solid #ddd;
        cursor: pointer;
        font-size: 13px;
        color: #000000;
        font-weight: 500;
        transition: 0.3s;
        height: 35px;
        min-width: 70px;
        background: #ffffff;

        &:hover {
            transform: scale(1.05);
        }
    }
}