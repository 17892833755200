.__premium_sec {
    background-color: #0780bd;
    border-radius: 30px;
    padding: 20px;
    color: #fff;

    h1 {
        font-size: 22px;
    }

    p {
        font-size: 16px;
        font-weight: bold;
        margin: 25px 0;
    }

    button {
        padding: 15px 20px;
        color: #6E6E6E;
        background-color: #fff;
        border: none;
        border-radius: 30px;
        width: 100%;
        font-weight: bold;
        font-size: 20px;
    }
}