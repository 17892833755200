.__slider_banner_sec {
    width: 100%;
    position: relative;
    
    img {
        width: 100%;
        height: 600px;
        object-fit: cover;
        transition: 0.4s;
        min-height: 600px;
    }

    .__img_thumb_set {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 30px;
        z-index: 9;

        img {
            width: 70px !important;
            height: 70px !important;
            object-fit: cover;
            margin: 0 10px;
            border: 3px solid #fff;
            border-radius: 10px;
            box-shadow: 0px 0px 10px #0005;
            cursor: pointer;
            transition: 0.4s;
            min-height: 70px !important;

            @media screen and (max-width: 560px) {
                width: 40px !important;
                height: 40px !important;
            }
        }

        .slick-current {
            img {
                border: 3px solid #4ac3f5;
            }
        }

        .__selected {
            border: 3px solid #4ac3f5;
        }
    }

}