.__hm_slider_outer {

    overflow: hidden;
    position: relative;

    .__hme_slider {
        width: 100%;
        height: 100%;
        background-size: cover;
        height: 80vh;
        padding: 10% 5% 0 5%;
        background-position: top left;


        h1 {
            font-size: 50px;
            font-weight: bold;
            width: fit-content;
            color: #fff;
            text-shadow: 0px 0px 10px #0003;
        }

        h3 {
            font-size: 25px;
            font-weight: normal;
            text-shadow: 0px 0px 30px #000;
        }
    }

    .__button {
        position: absolute;
        z-index: 1;
        background-color: transparent;
        border: none;
        bottom: 5%;
        font-size: 33px;
        color: #ffffffc2;
        font-weight: bold;
        transition: 0.4s;
        text-shadow: 0px 0px 4px #000;

        &:hover {
            color: #ffffff;
        }

        @media screen and (max-width: 555px) {
            span {
                display: none;
            }
        }
    }

    .__gradient {
        height: 120px;
        background: linear-gradient(180deg, #fff0 0%, #000000ba 100%);
        width: 100%;
        position: absolute;
        z-index: 1;
        bottom: 0;
    }
}


.__pop_sec {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .__left_set {
        width: 25%;
        max-height: 555px;
        overflow: hidden;
        @media screen and (max-width: 500px) {
            display: none;
        }

        .__sing_trip {
            position: relative;
            padding-right: 20px;

            img {
                width: 100%;
                height: 175px;
                object-fit: cover;
                border-radius: 27px;
                background: #eee;
                min-height: 175px;
                min-width: 100%;
                text-align: center;

            }

            h1 {
                font-size: 18px;
                margin-bottom: 15px;
                font-weight: bold;
                width: 100%;
                padding-bottom: 10px;
                margin-top: 15px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 18px;
                height: 18px;
            }

            p {
                padding-top: 10px;
                border-top: 1px solid #7474744f;
                font-size: 16px;
                color: #747474cf;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .arrow-right {
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                position: absolute;
                top: 72px;
                right: 10px;
                border-left: 10px solid #fff;
            }
        }

        .slick-current {
            .__sing_trip {

                img {
                    border: 3px solid #0083bb;
                }

                h1 {
                    color: #0083bb;
                }

                p {
                    color: #4ac3f5;
                }

                .arrow-right {
                    border-left: 10px solid #0083bb;
                }
            }
        }
    }

    .__right_set {
        width: 70%;
        @media screen and (max-width: 500px) {
            width: 100%;
        }
        .__on__mb{
            @media screen and (max-width: 500px) {
                flex-direction: column-reverse;
                align-items: initial !important;
            }
            button{
                margin-bottom: 20px !important;
            }
        }
    }
}

.__article_slider_outer {
    position: relative;

    .__button {
        border-radius: 50px;
        height: 50px;
        width: 50px;
        position: absolute;
        top: 44%;
        border: none;
        color: #555;
        background: #fff;
        box-shadow: 0px 0px 10px;
    }
}

.__latest_article {
    height: 60vh;
    width: 100%;
    border-radius: 40px;
    overflow: hidden;
    position: relative;
    display: flex !important;
    padding: 6%;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }

    h1 {
        font-size: 35px;
        margin-bottom: 10px;
        padding-bottom: 19px;
        border-bottom: 1px solid;
        width: fit-content;
    }

    p {
        font-size: 20px;
        font-weight: normal;
    }

    .__gradient {
        height: 250px;
        background: linear-gradient(0deg, #fff0 0%, #00000091 100%);
        width: 100%;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
    }

}

.__award_list {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: calc(100% - 140px);
    position: relative;
    left: 70px;
}

.__award_lists {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;

}

.__line {
    width: 100%;
    border-bottom: 1px solid #ffffffb5;
    position: absolute;
}

.__single_award {
    text-align: center;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    color: #fff;
    z-index: 1;
    position: relative;
    margin-right: 30px;
    width: 150px;

    &:after {
        width: 30px;
        height: 1px;
        background: #fff;
        content: '';
        position: absolute;
        right: -30px;
    }

    &:last-child {
        margin-right: 0px;

        &:after {
            display: none;
        }
    }

    h1 {
        font-size: 23px;
        margin: 10px 0;
        min-height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 0%;
    }

    .__round {
        width: 16px;
        height: 16px;
        background-color: #fff;
        border-radius: 10px;
    }

    .__dashed {
        height: 110px;
        border-left: 2px dashed;
    }

    .__year {
        width: 140px;
        background: #fff;
        box-shadow: 0px 0px 10px #0004;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        font-size: 22px;
        font-weight: bold;
        margin: 30px 0;
        color: #000;
    }

    .imgss {
        width: 140px;
        height: 240px;
        object-fit: cover;
        border-radius: 30px;
    }

    .__category {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1;
        font-size: 13px;
        position: absolute;
        top: 23px;
        right: -13px;
        background: #d30505;
        padding: 4px 10px;
        border-radius: 10px 0;
        box-shadow: 0px 0px 10px #0004;
        text-align: center;
        width: 100%;
        word-break: break-all;
    }

    .__category_bot {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1;
        font-size: 13px;
        position: absolute;
        bottom: 23px;
        left: -13px;
        background: #d30505;
        padding: 4px 10px;
        border-radius: 10px 0;
        box-shadow: 0px 0px 10px #0004;
        text-align: center;
        width: 100%;
        word-break: break-all;
    }
}

.__award_button {
    border-radius: 50px;
    height: 43px;
    width: 43px;
    border: none;
    color: #555;
    background: #fff;
    box-shadow: 0px 0px 10px #0003;
    transition: 0.4s;
    z-index: 1;

    &:hover {
        box-shadow: 0px 0px 10px #0006;
    }
}

.__winning {
    img {
        width: 100%;
        height: 160px;
        object-fit: cover;
        border-radius: 20px;
    }

    h1 {
        font-size: 22px;
        margin: 0;
        margin-bottom: 10px;
    }

    p {
        font-size: 18px;
        margin: 0;
    }
}

.arabic {
    .__hme_slider {
        direction: rtl;
    }
}


.text_wrap {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}