.__tab_wrapper {
    display: flex;
    margin: 40px 0;
    @media screen and (max-width: 555px) {
        flex-direction: column;

    }

    .__tabs {
        flex: 3;

        .MuiTabs-indicator {
            display: none;
        }

        .__tab_single {
            text-transform: capitalize;
            padding: 15px 35px;
            border-radius: 30px;
            text-decoration: none;
            transition: 0.3s;
            width: 100%;
            text-align: left;
            display: flex;
            align-items: baseline;
            font-size: 20px;
            font-weight: 600;
            border: none;
            background: none;
            color: #5b6269;
            font-family: 'Readex Pro', sans-serif;

            &.Mui-selected {
                background-color: #4ac3f5;
                color: #fff;
            }
        }

    }

    .__tab_content {
        flex: 7;
        padding: 0 30px;

        .__trips_grid_list {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
            grid-gap: 30px;

        }
    }
}




.profile {
    width: 300px;
    margin: 0 auto;
    text-align: center;

    .prfImg {
        position: relative;
        width: 100px;
        margin: 0 auto;

        img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border: 1px solid #0002;
            border-radius: 100px;
        }

        .savePic {
            width: 30px;
            background: #ffffff;
            text-align: center;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            text-decoration: none;
            border-radius: 25px;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 11;
            color: #42c5f3;
            box-shadow: 0px 0px 10px #0003;
        }
    }

    .profCont {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            font-size: 20px;
            margin: 0;
        }

        a {
            font-size: 15px;
            padding: 15px 0;
            cursor: pointer;
            border-bottom: 1px solid #ddd;
            width: 300px;
            display: flex;
            align-items: center;
            color: #555;
            text-decoration: none;

            &:hover {
                color: #4ac3f5;
                transition: 0.4s;
            }
        }
    }
}

.__trip_edit_menu {
    .MuiPopover-paper {
        border-radius: 10px;
        box-shadow: 0px 0px 14px #0003;
        padding: 0 5px;
        margin-top: 4px;
    }
}


.__trip_dot_icon {
    text-align: right;
    position: absolute;
    z-index: 1;
    right: -10px;
    background: #ffffff;
    border-radius: 37px;
    box-shadow: 0px 0px 10px #00000040;
    top: -10px;
}





.wallet {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    box-shadow: 0px 0px 10px #0003;
    border-radius: 10px;

    img {
        width: 100%;
        margin-bottom: 10px;
        height: 210px;
        object-fit: cover;
    }
}

.walletMoney span {
    display: flex;
    align-items: flex-end;
    font-size: 17px;
    font-weight: 500;
    margin: 6px 0;

    span h1 {
        margin: 0 10px;
        font-size: 34px;
        font-weight: bold;
    }
}

.refEarnings {
    border-top: 1px solid #ddd;
    width: 100%;
    margin-top: 20px;
    padding: 20px;

    h1 {
        margin: 0 10px;
        font-size: 27px;
        font-weight: bold;
    }

    span {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h4 {
        font-size: 20px;
        font-weight: bold;
        color: #666;
    }
}

.___subscriptioName {
    background: #c70000;
    border: 0;
    padding: 4px 20px;
    border-radius: 30px;
    color: #fff;
    margin-bottom: 10px;
}