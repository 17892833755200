.__header {
    height: 80px;
    background: #0780bd 0% 0% no-repeat padding-box;
    padding: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    z-index: 11;
    position: fixed;

    @media screen and (max-width: 555px) {
        height: 60px;
    }

    .__logo {
        height: 50px;
        object-fit: contain;
        border-radius: 8px;
        border: 2px solid #fff;
        cursor: pointer;

        @media screen and (max-width: 555px) {
            height: 40px;
        }
    }

    .__menu {
        color: #fff;

        @media screen and (max-width: 999px) {
            display: none;
        }

        a {
            font-size: 15px;
            font-weight: 400;
            margin: 0 20px;
            cursor: pointer;
            color: #fff;
            text-decoration: none;
            transition: 0.4s;
        }

        .active {
            font-weight: bold;
            text-shadow: 0px 0px 10px #0003;
        }
    }

    .__profile_sec {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 10px;

        .__prof-btn {
            color: #fff;
            text-transform: capitalize;
            font-weight: 400;
            font-size: 18px;
        }
    }

    .__add_trip {
        width: 140px;
        height: 50px;
        background: #ffffff61 0% 0% no-repeat padding-box;
        border-radius: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 14px;
        cursor: pointer;

        @media screen and (max-width: 555px) {
            display: none;
        }

        span {
            margin: 0 5px;
        }

        svg {
            margin-right: 5px;
            margin-left: -5px;
        }
    }

    .__dr_menu {

        button {
            border: none;
            background: none;
            margin-right: 10px;
            outline: none;
        }
    }

    .__add_trip_small {
        display: none;
        position: fixed;
        right: 20px;
        bottom: 20px;
        z-index: 11;

        @media screen and (max-width: 555px) {
            display: block;

        }
    }

}

.__drawer_menu {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    

    a {
        margin-bottom: 20px;
        font-size: 20px;
        cursor: pointer;
        color: inherit;
        text-decoration: none;
        width: max-content;
        display: flex;
        align-items: center;
        svg{
            margin: 0 10px;
        }
    }

    .active {
        text-decoration: none;
        color: #0780bd;
    }
}

.__logo_div {
    margin: 0 -30px;
    background: #0780bd;
    margin-top: -20px;
    margin-bottom: 20px;
    padding: 20px 30px;

    img {
        height: 50px;
        object-fit: contain;
        border-radius: 8px;
        border: 2px solid #fff;
        cursor: pointer;
    }
}

.lang_btn {
    background: none;
    color: #fff;
    font-size: 14px;
    border: none;
}

.arabic{
    .__drawer_menu{
        direction: rtl;
    }
}