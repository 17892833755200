.__chipset {
    text-align: center;
    margin: 20px 0;

    a {
        padding: 15px 25px;
        border: 1px solid #969EAE;
        margin: 15px;
        border-radius: 30px;
        text-decoration: none;
        color: #969EAE !important;
        transition: 0.4s;
        cursor: pointer;

        @media screen and (max-width: 555px) {
            float: left
        }

    }

    .selected {
        background-color: #0780bd;
        border: 1px solid #0780bd !important;
        color: #fff !important;
    }
}


.__pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 500px) {
        flex-direction: column;
        height: 120px;
        justify-content: space-evenly;
    }

    Button {
        padding: 12px 25px;
        border: 1px solid #969EAE;
        margin: 15px;
        border-radius: 30px;
        text-decoration: none;
        transition: 0.4s;
        height: 60px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;

        @media screen and (max-width: 900px) {
            font-size: 13px;
            padding: 2px;
            margin: 5px;
            height: 25px;
            width: 25px;
            min-width: 25px;
        }
    }

    ._hand_btn {
        color: #747474;
        text-transform: capitalize;
        font-size: 17px;
        font-weight: 400;
        font-family: 'Readex Pro' !important;
        width: auto;

        @media screen and (max-width: 900px) {
            padding: 12px;
            font-size: 13px;
        }
    }

    .css-1ysyrvn-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
        color: #ffffff;
        border: 1px solid #39c7f1;
        background-color: #39c7f1;
    }
}