.__search_item {
    display: flex;
    height: 70px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px #0002;
    padding: 10px;
    overflow: hidden;
    align-items: center;
    cursor: pointer;
    transition: 0.4s;
    &:hover{
        box-shadow: 0px 0px 10px #0004;
    }
    img {
        width: 50px;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        background-color: #ddd;
    }
    p {
        margin: 0 15px;
    }
}