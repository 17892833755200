.__cancel {
    cursor: pointer;
    margin: 10px;
    padding: 5px 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px #00000042;
    color: #333;
    text-decoration: none;
}

.__check_list {
    max-height: 35vh;
    overflow-y: auto;
    margin: 20px;
    padding-right: 20px;
    margin-right: 0;

    .__user_wrap {
        display: flex;
        margin-bottom: 15px;
        justify-content: space-between;
        align-items: center;

        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 60px;
            margin-right: 15px;
        }

        h1 {
            font-size: 17px !important;
            margin: 0
        }

        p {
            font-size: 14px;
            color: #666;
            margin: 0;
        }

    }
}