.__comment_wrap {
    display: flex;
    margin-bottom: 25px;

    img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 60px;
    }

    h1 {
        font-size: 16px !important;
        font-weight: 400 !important;
        margin-bottom: 0 !important;

        span {
            font-size: 13px;
            margin: 0 10px;
            color: #aaa;
            font-weight: 400;
        }
    }

    p {
        font-size: 16px !important;
        color: #666 !important;
        margin-top: 0px !important;
        margin-bottom: 0 !important;
    }

}