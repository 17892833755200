.map-container {
    width: 100vw;
    height: 80vh;
    position: relative;
}

.fs-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
    border-radius: 20px;
    box-shadow: 0px 0px 10px #0003;
    border: 2px solid #fff;
}
.leaflet-container {
    outline: 0;
    cursor: -moz-grab;
    overflow: hidden;
    touch-action: none;
    font-family: 'Readex Pro', sans-serif;
    font-size: 12px;
    line-height: 1.5;
    background: #ddd;
    outline-offset: 1px;
    -webkit-tap-highlight-color: transparent;
}