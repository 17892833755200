.__tab_content{
    padding: 20px;
    padding-top: 35px;
    img{
        width: 100%;
        height: 200px;
        object-fit: contain;
    }
    h1{
        font-size: 30px;
    font-weight: bold;
    margin-bottom: 15px;
    }
    p{
        font-size: 18px;
    line-height: 2;
    text-align: justify;
    }
    .__heigh{
        height: 50vh;
        overflow-y: auto;
    }
}