.__select {
    position: relative;
    width: 100%;

    label {
        font-size: 0.9rem;
        margin-bottom: 10px;
        display: block;
        font-weight: 600;
    }

    .MuiOutlinedInput-root {
        width: 100% !important;
        display: block !important;
        border: 1px solid #969fad !important;
        outline: none !important;
        background-color: #fff !important;
        height: 48px !important;
        font-size: .8rem !important;
        padding: 0 20px !important;
        border-radius: 40px !important;
        font-weight: 400 !important;
        color: #6e6e6e !important;
        transition: 0.1s;

        &:focus {
            border: 2px solid;
        }

        .MuiOutlinedInput-input {
            padding: 0 !important;
            display: flex;
            align-items: center;
            height: 100%;
            font-family: 'Readex Pro', sans-serif;
            font-size: .9rem;
        }

        fieldset {
            border: none;
            border-style: none;
            border-color: transparent;
        }

        svg {
            right: 11px !important;
        }

        &:hover {
            fieldset {
                border: none;
                border-style: none;
                border-color: transparent;
            }
        }
    }
}

.__error {
    .MuiOutlinedInput-root {
        border-color: #e35061 !important;
    }

    label {
        color: #e35061 !important;
    }
}

.arabic {
    .__select {
        direction: rtl;

        .MuiOutlinedInput-root {
            svg {
                right: auto !important;
                left: 11px !important;
            }
        }
    }
}