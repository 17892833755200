._custom_input{ input {
    width: 100% !important;
    display: block;
    border: 1px solid #969fad;
    outline: none;
    background-color: #fff;
    height: 48px;
    font-size: 1.5rem;
    padding: 0 10px;
    border-radius: 40px;
    font-weight: 400;
    color: #6e6e6e;
    margin: 5px 15px;
}}