.__read_now {
    background: #0083bb;
    padding: 5px 20px;
    border-radius: 21px;
    color: #fff;
    text-decoration: none;
    &:hover{
        color: #fff;
        text-decoration: none;
    }
}